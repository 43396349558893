import Control from "./Control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

export const TextInput = ({ inputLeftElement, inputRef, ...rest }) => {
  return (
    <Control {...rest}>
      {field => (
        <InputGroup>
          {inputLeftElement && (
            <InputLeftElement
              {...inputLeftElement}
              h={{ base: "70px", md: "60px" }}
              px={2}
            />
          )}
          <Input {...field} {...rest} ref={inputRef} />
        </InputGroup>
      )}
    </Control>
  );
};

export default TextInput;
