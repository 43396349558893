import ClickHereImage from "../../../../assets/images/click-here.png";
import { useNavigate } from "react-router-dom";
import {
  Image,
  VStack,
  Button,
  Circle,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";

export const PurchaseTicket = props => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  const onClick = () => navigate("ticketing");
  if (bp === "base" || bp === "sm")
    return (
      <RectRedButton
        onClick={onClick}
        mx={{ sm: "auto" }}
        pos="sticky"
        bottom={4}
        {...props}
      />
    );
  return (
    <ClickHere
      onClick={onClick}
      gridColumn={{ md: 2 }}
      gridRow={{ md: 2 }}
      alignSelf="end"
      pos="sticky"
      bottom="101px"
      mb={50}
      {...props}
    />
  );
};

export const RectRedButton = props => (
  <Button
    size="xl"
    colorScheme="red"
    _active={{ boxShadow: "0 0 0 5px white" }}
    _focus={{ boxShadow: "0 0 0 0 5px white" }}
    {...props}
  >
    Purchase Ticket
  </Button>
);

export const ClickHere = ({ onClick, ...rest }) => (
  <VStack {...rest}>
    <Image src={ClickHereImage} alt="click here" boxSize={40} />
    <RoundRedButton onClick={onClick} pos="relative" left={23} bottom={-2} />
  </VStack>
);

export const RoundRedButton = props => {
  return (
    <Button
      colorScheme="transparent"
      outline="none"
      _active={{ boxShadow: "none" }}
      _focus={{ boxShadow: "none" }}
      {...props}
    >
      <Circle
        size={28}
        bg="red.500"
        _hover={{ bg: "red.600" }}
        _active={{ boxShadow: "0 0 0 5px white" }}
        _focus={{ boxShadow: "0 0 0 5px white" }}
        sx={{ transition: "all 200ms ease-in-out" }}
      >
        <VStack spacing={1}>
          <Text fontSize="lg">Purchase</Text>
          <Text fontSize="lg">Ticket</Text>
        </VStack>
      </Circle>
    </Button>
  );
};
