const env = process.env.NODE_ENV;

const inDevelopment = env === "development";

export const jotform = {
  apiKey: process.env.REACT_APP_JOTFORM_API_KEY,
};

const authorizeNetSandbox = {
  baseUrl: "https://apitest.authorize.net/xml/v1/request.api",
  apiLoginKey: process.env.REACT_APP_AUTHORIZE_NET_SANDBOX_API_LOGIN_ID,
  transactionKey: process.env.REACT_APP_AUTHORIZE_NET_SANDBOX_TRANSACTION_KEY,
  key: process.env.REACT_APP_AUTHORIZE_NET_SANDBOX_KEY,
};

const authorizeNetProduction = {
  baseUrl: "https://api.authorize.net/xml/v1/request.api",
  apiLoginKey: process.env.REACT_APP_AUTHORIZE_NET_API_LOGIN_ID,
  transactionKey: process.env.REACT_APP_AUTHORIZE_NET_TRANSACTION_KEY,
};

export const authorizeNet = inDevelopment
  ? authorizeNetSandbox
  : authorizeNetProduction;
