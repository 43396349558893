import { VStack, Text, Link } from "@chakra-ui/react";
import { Title } from "../markup";

export const ContactUs = props => {
  return (
    <VStack spacing={10} {...props} align="left">
      <Title>Contact Us</Title>
      <VStack spacing={1} align="left">
        <Text>Mailing address:</Text>
        <Text>P.O. Box 755</Text>
        <Text>Oradell, NJ 07649</Text>
      </VStack>

      <VStack spacing={1} align="left">
        <Text>Physical Address:</Text>
        <Text>19 Windsor Road</Text>
        <Text>Oradell, New Jersey 07649</Text>
        <Text>USA</Text>
      </VStack>

      <VStack spacing={2} align="left">
        <Text>
          Phone: <Link href="tel:201-673-3566">(201) 673-3566</Link>
        </Text>
        <Text>
          Email:{" "}
          <Link href="mailto:info@njforward.org">info@njforward.org</Link>
        </Text>
      </VStack>
    </VStack>
  );
};
