import * as Yup from "yup";
import "yup-phone-lite";

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "Must be at least 2 characters"),
  lastName: Yup.string()
    .required("This field is required")
    .min(2, "Must be at least 2 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required."),
  phone: Yup.string()
    .phone("US", "Invalid phone #")
    .required("This field is required."),
});

export default validationSchema;
