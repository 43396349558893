export const Input = {
  variants: {
    filled: () => ({
      field: {
        h: { base: "70px", md: "60px" },
        fontSize: { base: "xl", md: "lg" },
        bg: "white",
        _hover: { bg: "gray.50" },
        _focus: { bg: "white" },
        _active: { bg: "white" },
      },
    }),
  },
};

export default Input;
