import { Section, Title, SubTitle, List } from "../markup";
import { ListItem, OrderedList, Text, VStack } from "@chakra-ui/react";

export const TermsOfService = props => (
  <VStack spacing={[4, 6, 8]} align="left" {...props}>
    <Title>Terms of Service</Title>
    <Section>
      <Text>Updated March 28, 2022</Text>
      <Text>
        NJ Forward.org (herein after referred to as, “NJF”, or “the Service”) is
        operated by NJ Forward. By accessing or using our website at
        njforward.org or by posting a NJF banner on your site, you (the “User”)
        signify that you have read, understand and agree to be bound by these
        Terms of Service (“Terms of Service” or “Agreement”), whether or not you
        are a registered member of NJF. We reserve the right, at our sole
        discretion, to change, modify, add, or delete portions of these Terms of
        Service at any time without further notice. If we do this, we will post
        the changes to these Terms of Service on this page and will indicate at
        the top of this page the date these terms were last revised, which shall
        constitute reasonable notice of such changes. Your continued use of the
        Service or the Site after any such changes constitutes your acceptance
        of the new Terms of Service. If you do not agree to abide by these or
        any future Terms of Service, do not use or access (or continue to use or
        access) the Service or the Site. It is your responsibility to regularly
        check the Site to determine if there have been changes to these Terms of
        Service and to review such changes. PLEASE READ THESE TERMS OF SERVICE
        CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL
        RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND
        EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES
        WILL BE RESOLVED.
      </Text>
    </Section>

    <Section>
      <SubTitle>Eligibility</SubTitle>
      <Text>
        This Site is intended solely for Users who are eighteen years of age or
        older. Any registration by, use of or access to the Sites by anyone
        under 18 is unauthorized, unlicensed and in violation of these Terms of
        Service. By using the Site, you represent and warrant that you are 18
        years or older and that you agree to abide by all of the terms and
        conditions of this Agreement. The Company may deny your access for any
        reason, at any time in its sole discretion, with or without notice,
        including without limitation if we believe that you are under 18.
      </Text>
    </Section>

    <Section>
      <SubTitle>Registration Data; Account Security</SubTitle>
      <Text>
        In consideration of your use of the Site, you agree to provide accurate,
        current and complete information about you as may be prompted by any
        registration or donation forms on the Site (“Registration Data”).
      </Text>
    </Section>

    <Section>
      <SubTitle>Registration Data; Account Security</SubTitle>
      <Text>
        In consideration of your use of the Site, you agree to provide accurate,
        current and complete information about you as may be prompted by any
        registration or donation forms on the Site (“Registration Data”).
      </Text>
    </Section>

    <Section>
      <SubTitle>Proprietary Rights in Site Content; Limited License</SubTitle>
      <Text>
        All content on the Site, including but not limited to designs, text,
        graphics, pictures, animation, video, information, software, music,
        sound and other files, and their selection and arrangement (the “Site
        Content”), are the proprietary property of the Company or its licensors
        with all rights reserved. No Site Content may be modified, copied,
        distributed, framed, reproduced, republished, downloaded, displayed,
        posted, transmitted, or sold in any form or by any means, in whole or in
        part, without the Company’s prior written permission, except that the
        foregoing does not apply to your own User Content (as defined below)
        that you legally post on the Sites. Provided that you are eligible for
        use of the Sites, you are granted a limited license to access and use
        the Site and to download or print a copy of any portion of the Sites
        Content solely for your personal, non-commercial use, provided that you
        keep all copyright or other proprietary notices intact. Except for your
        own User Content, you may not republish Site Content on any Internet,
        Intranet or Extranet site or incorporate the information in any other
        database or compilation, whether published or unpublished. Any other use
        of the Site Content is strictly prohibited. Such license is subject to
        these Site Terms of Service and does not include use of any data mining,
        robots or similar data gathering or extraction methods. Any use of the
        Site or the Sites Content other than as specifically authorized herein,
        without the prior written permission of the Organization, is strictly
        prohibited and will terminate the license granted herein. Such
        unauthorized use may also violate applicable laws including without
        limitation copyright and trademark laws and applicable communications
        regulations and statutes. Unless explicitly stated herein, nothing in
        these Site Terms of Service shall be construed as conferring any license
        to intellectual property rights, whether by estoppel, implication or
        otherwise. This license is revocable at any time without notice and with
        or without cause.
      </Text>
    </Section>

    <Section>
      <SubTitle>Trademarks</SubTitle>
      <Text>
        NJ Forward, and other Company graphics, logos, designs, page headers,
        button icons, scripts and service names are the sole property of NJ
        Forward. The Company’s logos and information may not be used, including
        as part of trademarks and/or as part of domain names, in connection with
        any product or service in any manner that is likely to cause confusion
        and may not be copied, imitated, or used, in whole or in part, without
        the prior written permission of the Company.
      </Text>
    </Section>

    <Section>
      <SubTitle>User Conduct</SubTitle>
      <Text>
        You understand that the Service and the Site are available for your
        personal, non-commercial use only. You represent, warrant and agree that
        no materials of any kind submitted through your account or otherwise
        posted or shared by you through the Service will violate or infringe
        upon the rights of any third party, including copyright, trademark,
        privacy, publicity or other personal or proprietary rights; or contain
        libelous, defamatory or otherwise unlawful material. You further agree
        not to harvest or collect email addresses or other contact information
        of Users from the Service or the Site by electronic or other means for
        the purposes of sending unsolicited emails or other unsolicited
        communications. Additionally, you agree not to use automated scripts to
        collect information from the Service or the Site or for any other
        purpose. You further agree that you may not use the Service or the Site
        in any unlawful manner or in any other manner that could damage,
        disable, overburden or impair the Sites. In addition, you agree not to
        use the Service or the Site to:
      </Text>
      <List>
        <ListItem>
          upload, post, transmit, share, store or otherwise make available any
          content that we deem to be harmful, threatening, unlawful, defamatory,
          infringing, abusive, inflammatory, harassing, vulgar, obscene,
          sexually explicit, containing nudity or erotic content, fraudulent,
          invasive of privacy or publicity rights, hateful, or racially,
          ethnically or otherwise objectionable;
        </ListItem>
        <ListItem>
          impersonate any person or entity, or falsely state or otherwise
          misrepresent yourself, your age or your affiliation with any person or
          entity;
        </ListItem>
        <ListItem>
          upload, post, transmit, share or otherwise make available any
          unsolicited or unauthorized advertising, solicitations, promotional
          materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or
          any other form of solicitation;
        </ListItem>
        <ListItem>
          upload, post, transmit, share, store or otherwise make publicly
          available on the Site any private information of any third party,
          including, without limitation, addresses, phone numbers, email
          addresses, Social Security numbers and credit card numbers;
        </ListItem>
        <ListItem>
          solicit personal information from anyone under 18 or solicit passwords
          or personally identifying information for commercial or unlawful
          purposes;
        </ListItem>
        <ListItem>
          upload, post, transmit, share or otherwise make available any material
          that contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer software or hardware or telecommunications equipment;
        </ListItem>
        <ListItem>intimidate or harass another;</ListItem>
        <ListItem>
          upload, post, transmit, share, store or otherwise make available
          content that would constitute, encourage or provide instructions for a
          criminal offense, violate the rights of any party, or that would
          otherwise create liability or violate any local, state, national or
          international law;
        </ListItem>
        <ListItem>
          use or attempt to use another’s account, service or system without
          authorization from the Company, or create a false identity on the
          Service or the Site;
        </ListItem>
        <ListItem>
          upload, post, transmit, share, store or otherwise make available
          content that, in the sole judgment of the Company, is objectionable or
          which restricts or inhibits any other person from using or enjoying
          the Sites, or which may expose the Company or its Users to any harm or
          liability of any type.
        </ListItem>
      </List>
    </Section>

    <Section>
      <SubTitle>Mobile or Other Devices</SubTitle>
      <Text>
        The Company currently provides our mobile services for free and
        convenience, but please be aware that your carrier’s normal rates and
        fees, such as text messaging fees, will still apply. In the event you
        change or deactivate your mobile telephone number, you will update your
        account information on the Site by contacting us within 48 hours to
        ensure that your messages are not sent to the person who acquires your
        old number.
      </Text>
    </Section>

    <Section>
      <SubTitle>Personal and Third-Party Appeals Prohibited</SubTitle>
      <Text>
        You agree that you will not use the Service or the Site to solicit for,
        raise funds for, advertise or promote your own goods, services, causes,
        organizations, foundations or charities (collectively, “Personal
        Appeals”), and you further agree that you will not use the Service or
        the Site to solicit for, raise funds for, advertise or promote the
        goods, services, causes, organizations, foundations or charities of
        others (collectively, “Third-Party Appeals”) including, without
        limitation, other advocacy organizations.
      </Text>
    </Section>

    <Section>
      <SubTitle>Intellectual Property Complaints</SubTitle>
      <Text>
        If you believe that any material on the Site infringes upon any
        copyright, trademark or other right which you own or control, you may
        send a written notification of such infringement to our Designated Agent
        as set forth below:
      </Text>
      <Text>
        Name of Agent Designated to Receive Notification of Claimed
        Infringement:
      </Text>
      <Text>NJ Forward</Text>
      <Text>c/o Jose Lozano</Text>
      <Text>P.O. Box 755</Text>
      <Text>Oradell, NJ 07649</Text>
      <Text>
        To meet the notice requirements under the Digital Millennium Copyright
        Act (DMCA), the notification must be a written communication that
        includes the following:
      </Text>
      <List type="ordered">
        <ListItem>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </ListItem>
        <ListItem>
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site;
        </ListItem>
        <ListItem>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
        </ListItem>
        <ListItem>
          Information reasonably sufficient to permit us to contact the
          complaining party, such as an address, telephone number and, if
          available, an electronic mail address at which the complaining party
          may be contacted;
        </ListItem>
        <ListItem>
          A statement that the complaining party has a good-faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent or the law; and
        </ListItem>
        <ListItem>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </ListItem>
      </List>
    </Section>

    <Section>
      <SubTitle>Repeat Infringer Policy</SubTitle>
      <Text>
        In accordance with the DMCA and other applicable law, the Company has
        adopted a policy of terminating, in appropriate circumstances and at the
        Company’s sole discretion, members who are deemed to be repeat
        infringers. The Company may also at its sole discretion limit access to
        the Site and/or terminate the rights of any Users who infringe any
        intellectual property rights of others, whether or not there is any
        repeat infringement.
      </Text>
    </Section>

    <Section>
      <SubTitle>User Disputes</SubTitle>
      <Text>
        You are solely responsible for your interactions with other NJF Users.
        We reserve the right, but have no obligation, to monitor disputes
        between you and other Users.
      </Text>
    </Section>

    <Section>
      <SubTitle>Privacy</SubTitle>
      <Text>
        We care about the privacy of our Users. Our PRIVACY POLICY is located on
        the home page of our Site. By using the Sites or the Service, you are
        consenting to have your personal data transferred to and processed in
        the United States.
      </Text>
    </Section>

    <Section>
      <SubTitle>Online Donations</SubTitle>
      <Text>
        You are responsible for the accuracy and legitimacy of any credit card
        information you provide on the Sites in order to make purchases or
        donations. By providing the information, you represent that such
        information is accurate, complete and correct and that you have the
        lawful right to provide such information. In order to make donations
        using the payment method displayed on the Sites, you must be 18 years of
        age or over. Billing to your credit card or to your account occurs at
        the time of donation or shortly thereafter. You expressly authorize the
        Company to charge the credit card provided by your or listed on your
        account for any donations submitted online via the Sites The Company
        uses industry-standard SSL (secure socket layer) technology to protect
        your information and provide a safe and secure environment for online
        donations. It will not sell, trade or share your personal information
        with anyone else, nor send donor mailings on behalf of other
        organizations, and will only share personal information if you have
        given it specific written permission to do so.
      </Text>
    </Section>

    <Section>
      <SubTitle>Disclaimers</SubTitle>
      <Text>
        The Site and the Service may be temporarily unavailable from time to
        time for maintenance or other reasons. The Company assumes no
        responsibility for any error, omission, interruption, deletion, defect,
        delay in operation or transmission, communications line failure, theft
        or destruction or unauthorized access to, or alteration of, User
        communications or Site Content. The Company is not responsible for any
        problems or technical malfunction of any telephone network or lines,
        computer online systems, servers or providers, computer equipment,
        software, failure of email or players on account of technical problems
        or traffic congestion on the Internet or at any Site or combination
        thereof, including injury or damage to Users or to any other person’s
        computer related to or resulting from participating or downloading
        materials in connection with the Web and/or in connection with the
        Service. Under no circumstances will the Company be responsible for any
        loss or damage, including any loss or damage to any User Content or
        personal injury or death, resulting from anyone’s use of the Site or the
        Service, any User Content or Third Party Content posted on or through
        the Sites or the Service or transmitted to Users, or any interactions
        between Users of the Sites, whether online or offline. THE SITE, THE
        SERVICE AND THE SITE CONTENT ARE PROVIDED “AS-IS” AND THE COMPANY
        DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR
        IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF TITLE,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
        THE COMPANY CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS
        FROM USE OF THE SITES AND/OR THE SERVICE. THE COMPANY DOES NOT REPRESENT
        OR WARRANT THAT CONTENT OR MATERIALS ON THE SITES ARE ACCURATE,
        COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT THE SITES OR ITS
        SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS The Company
        reserves the right to change any and all content contained in the Site
        and any Services offered through the Sites at any time without notice.
        Reference to any products, services, processes or other information, by
        trade name, trademark, manufacturer, supplier or otherwise does not
        constitute or imply endorsement, sponsorship or recommendation thereof,
        or any affiliation therewith, by the Company.
      </Text>
    </Section>

    <Section>
      <SubTitle>Governing Law;Venue and Jurisdiction</SubTitle>
      <Text>
        By visiting or using the Site and/or the Service, you agree that the
        laws of the State of New Jersey, without regard to principles of
        conflict of laws, will govern these Terms of Service and any dispute of
        any sort that might arise between you and the Company or any of our
        affiliates. With respect to any disputes or claims not subject to
        arbitration (as set forth below), you agree not to commence or prosecute
        any action in connection therewith other than in the state and federal
        courts of New Jersey, and you hereby consent to, and waive all defenses
        of lack of personal jurisdiction and forum non conveniens with respect
        to, venue and jurisdiction in the state and federal courts of New
        Jersey.
      </Text>
    </Section>

    <Section>
      <SubTitle>Arbitration</SubTitle>
      <Text>
        YOU AND THE COMPANY AGREE THAT THE SOLE AND EXCLUSIVE FORUM AND REMEDY
        FOR ANY AND ALL DISPUTES AND CLAIMS RELATING IN ANY WAY TO OR ARISING
        OUT OF THESE SITE TERMS OF SERVICE, THE SITES AND/OR THE SERVICE
        (INCLUDING YOUR VISIT TO OR USE OF THE SITES AND/OR THE SERVICE) SHALL
        BE FINAL AND BINDING ARBITRATION, except that, to the extent that either
        party has in any manner infringed upon or violated or threatened to
        infringe upon or violate the other party’s patent, copyright, trademark
        or trade secret rights, such other party may seek injunctive or other
        appropriate relief. Arbitration under this Agreement shall be conducted
        by the American Arbitration Association (the “AAA”) under its Commercial
        Arbitration Rules and, in the case of consumer disputes, the AAA’s
        Supplementary Procedures for Consumer Related Disputes ( the “AAA
        Consumer Rules”) (collectively, with the AAA, the “AAA Rules”). To the
        fullest extent permitted by applicable law, NO ARBITRATION OR CLAIM
        UNDER THESE TERMS OF SERVICE SHALL BE JOINED TO ANY OTHER ARBITRATION OR
        CLAIM, INCLUDING ANY ARBITRATION OR CLAIM INVOLVING ANY OTHER CURRENT OR
        FORMER USER OF THE SERVICE, AND NO CLASS ARBITRATION PROCEEDINGS SHALL
        BE PERMITTED. In no event shall any claim, action or proceeding by you
        related in any way to the Sites and/or the Service (including your visit
        to or use of the Sites and/or the Service) be instituted more than three
        (3) years after the cause of action arose.
      </Text>
    </Section>

    <Section>
      <SubTitle>Indemnity</SubTitle>
      <Text>
        You agree to indemnify and hold the Company, its subsidiaries and
        affiliates, and each of their directors, officers, agents, contractors,
        partners and employees, harmless from and against any loss, liability,
        claim, demand, damages, costs and expenses, including reasonable
        attorney’s fees, arising out of or in connection with any User Content,
        any Third Party Content you post or share on or through the Sites, your
        use of the Service, the Sites, your conduct in connection with the
        Service or the Site or with other Users of the Service or the Site, or
        any violation of this Agreement or of any law or the rights of any third
        party.
      </Text>
    </Section>

    <Section>
      <SubTitle>Submissions</SubTitle>
      <Text>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback or other information about the Site or the Service
        (“Submissions”), provided by you to the Company are non-confidential and
        shall become the sole property of the Company. The Company shall own
        exclusive rights, including all intellectual property rights, and shall
        be entitled to the unrestricted use and dissemination of these
        Submissions for any purpose, commercial or otherwise, without
        acknowledgment or compensation to you.
      </Text>
    </Section>

    <Section>
      <SubTitle>Other</SubTitle>
      <Text>
        These Terms of Service constitute the entire agreement between you and
        the Company regarding the use of the Site and/or the Service,
        superseding any prior agreements between you and the Company relating to
        your use of the Site or the Service. The failure of the Company to
        exercise or enforce any right or provision of these Terms of Service
        shall not constitute a waiver of such right or provision in that or any
        other instance. If any provision of this Agreement is held invalid, the
        remainder of this Agreement shall continue in full force and effect. If
        any provision of these Site Terms of Service shall be deemed unlawful,
        void or for any reason unenforceable, then that provision shall be
        deemed severable from these Terms of Service and shall not affect the
        validity and enforceability of any remaining provisions.
      </Text>
    </Section>

    <Section>
      <SubTitle>Questions</SubTitle>
      <Text>Please contact us for more information.</Text>
    </Section>
  </VStack>
);
