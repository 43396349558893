import TotalPrice from "../TotalPrice";
import validationSchema from "./validationSchema";
import { Formik, Form } from "formik";
import { SubmitButton } from "../../../../form";
import { VStack, Center, Grid, Heading } from "@chakra-ui/react";
import { usePaymentFormUtils } from "./hooks";
import { useRedirectToTicketing } from "../../hooks";
import { useState } from "react";

import {
  NameOnCard,
  CardNumber,
  ExpiryMonth,
  ExpiryYear,
  SecurityCode,
} from "./components";

export const PaymentForm = () => {
  const [card, setCard] = useState();
  const { onCardNumberChange, ...rest } = usePaymentFormUtils(setCard);
  useRedirectToTicketing();
  return (
    <VStack>
      <Heading>Payment</Heading>
      <TotalPrice />
      <Formik validationSchema={validationSchema(card)} {...rest}>
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <VStack spacing={3} minW={300} maxW={415} m="0 auto">
              <NameOnCard />
              <CardNumber card={card} onChange={onCardNumberChange} />
              <Grid autoFlow="column" alignItems="start" gap={5}>
                <ExpiryMonth />
                <ExpiryYear />
                <SecurityCode card={card} />
              </Grid>
            </VStack>
            <Center mt={8}>
              <SubmitButton
                isLoading={isSubmitting}
                disabled={Object.values(values).includes("") || !isValid}
              />
            </Center>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default PaymentForm;
