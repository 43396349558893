import Footer from "../../Footer";
import { Grid, Heading } from "@chakra-ui/react";
import { Routes, Route, Outlet } from "react-router-dom";
import {
  SelectNumTickets,
  Registration,
  PaymentForm,
  Disclaimers,
  ThankYou,
  NJForwardLogo,
} from "./components";

export const Ticketing = () => {
  return (
    <Grid
      minH="100vh"
      templateRows="1fr auto auto"
      bgGradient="linear(to-br, gray.50, gray.300)"
      px={4}
    >
      <Grid
        alignItems={{ base: "start", md: "center" }}
        autoFlow={{ base: "row", md: "column" }}
        gap={{ md: 10 }}
        gridTemplateRows={{ base: "auto 1fr", md: "auto" }}
        justifyContent={{ base: "unset", md: "center" }}
        justifyItems="center"
        pb={8}
        pt={[4, 4, 8]}
        px={[4, 8, 12]}
      >
        <NJForwardLogo />
        <Routes>
          <Route index element={<SelectNumTickets />} />
          <Route path="registration" element={<RegistrationLayout />}>
            <Route index element={<Registration />} />
            <Route path="guest-:guestNum" element={<Registration />} />
          </Route>
          <Route path="payment">
            <Route index element={<PaymentForm />} />
            <Route path="thank-you" element={<ThankYou />} />
          </Route>
        </Routes>
      </Grid>
      <Disclaimers />
      <Footer color="gray.500" />
    </Grid>
  );
};

export const RegistrationLayout = () => {
  return (
    <Grid justifyItems="center">
      <Heading mb={4}>Registration</Heading>
      <Outlet />
    </Grid>
  );
};

export default Ticketing;
