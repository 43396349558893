import { Grid } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import { InvitationText, Logo, PurchaseTicket, StadiumBg } from "./components";
import {
  About,
  ContactUs,
  PrivacyPolicy,
  TermsOfService,
  TicketPolicy,
} from "./components/pages";

export const Home = props => {
  return (
    <StadiumBg>
      <Header />
      <Grid
        templateColumns={{ md: "1fr auto" }}
        autoFlow={{ md: "column" }}
        alignContent="center"
        w="full"
        maxW="70em"
        mx={{ xl: "auto" }}
        mb={[4, 8, 0]}
        rowGap={{ base: 10, md: 170 }}
        columnGap={{ md: 50 }}
        px={[4, 8, 12]}
        py={[0, 8]}
      >
        <Routes>
          <Route
            index
            element={<InvitationText zIndex={1} gridRow={{ md: "span 2" }} />}
          />
          <Route
            path="about"
            element={<About zIndex={1} gridRow={{ md: "span 2" }} />}
          />
          <Route
            path="contact-us"
            element={<ContactUs zIndex={1} gridRow={{ md: "span 2" }} />}
          />
          <Route
            path="ticket-policy"
            element={<TicketPolicy zIndex={1} gridRow={{ md: "span 2" }} />}
          />
          <Route
            path="privacy-policy"
            element={<PrivacyPolicy zIndex={1} gridRow={{ md: "span 2" }} />}
          />
          <Route
            path="terms-of-service"
            element={<TermsOfService zIndex={1} gridRow={{ md: "span 2" }} />}
          />
        </Routes>
        <Logo
          justifySelf={{ base: "center", md: "unset" }}
          boxSize={{ base: 28, md: 44 }}
          gridColumn={{ md: 2 }}
          gridRow={{ base: 1, md: 1 }}
          pos={{ base: "static", md: "sticky" }}
          top="105px"
          zIndex={1}
        />
        <PurchaseTicket zIndex={2} {...props} />
      </Grid>
      <Footer zIndex={1} color="gray.300" />
    </StadiumBg>
  );
};

export default Home;
