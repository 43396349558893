import { HStack, Text } from "@chakra-ui/react";
import { GuestContext } from "../../../context";
import { useContext } from "react";

export const TotalPrice = () => {
  const { totalPrice } = useContext(GuestContext);
  return (
    <HStack align="left" w="full" pt={3}>
      <Text color="black">Total:</Text>
      <Text color="gray.600">${totalPrice}</Text>
    </HStack>
  );
};

export default TotalPrice;
