import { useContext } from "react";
import { useField } from "formik";
import { SelectInput } from "../../../../../form";
import { GuestContext } from "../../../../../context";

export const NumTicketsInput = () => {
  const { setNumGuests } = useContext(GuestContext);
  const [, , { setValue }] = useField("numGuests");
  const numbers = Array.from({ length: 10 }).map((_, index) => index + 1);
  return (
    <SelectInput
      textAlign="center"
      fontSize="2xl"
      isRequired
      label="How many tickets would you like to purchase?"
      name="numGuests"
      type="number"
      onChange={({ target: { value } }) => {
        setValue(value);
        setNumGuests(value);
      }}
    >
      {numbers.map(n => (
        <option value={n} key={n}>
          {n}
        </option>
      ))}
    </SelectInput>
  );
};

export default NumTicketsInput;
