import { useEffect } from "react";
import { Home, Ticketing } from "./pages";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

export const App = () => {
  const location = useLocation();
  useEffect(() => {
    // scroll window to top on location change
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="ticketing/*" element={<Ticketing />}></Route>
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};

export default App;
