import { Center, Image } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import Amex from "../../assets/card-icons/amex.svg";
import Jcb from "../../assets/card-icons/jcb.svg";
import Visa from "../../assets/card-icons/visa.svg";
import Discover from "../../assets/card-icons/discover.svg";
import Diners from "../../assets/card-icons/diners.svg";
import Mastercard from "../../assets/card-icons/mastercard.svg";
import Maestro from "../../assets/card-icons/maestro.svg";
// Union Pay
// Mir
// Elo
// Hiper
// Hipercard

export const CardIcon = props => {
  return (
    <Center>
      <CardImage {...props} />
    </Center>
  );
};

const CardImage = ({ type }) => {
  if (!type) return <Icon as={BsCreditCard2FrontFill} color="blue.600" />;
  if (type === "american-express") return <Image src={Amex} />;
  if (type === "mastercard") return <Image src={Mastercard} />;
  if (type === "maestro") return <Image src={Maestro} />;
  if (type === "visa") return <Image src={Visa} />;
  if (type === "jcb") return <Image src={Jcb} />;
  if (type === "discover") return <Image src={Discover} />;
  if (type === "diners-club") return <Image src={Diners} />;
  return <Icon as={BsCreditCard2FrontFill} color="blue.600" />;
};

export default CardIcon;
