import { useToast } from "@chakra-ui/react";

export const usePaymentToast = () => {
  const toast = useToast();
  const displayToast = (status, err) => {
    if (status === "success") {
      toast({
        title: "Payment Complete",
        position: "top",
        description:
          "We've successfully received your payment. Once verified, you will receive a text message to complete the ticketing and registration process",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (status === "error")
      toast({
        title: "Payment Failed",
        position: "top",
        description: err.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  };
  return displayToast;
};
