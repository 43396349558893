import { useState, createContext } from "react";
import * as day from "dayjs";

export const GuestContext = createContext();

export const GuestProvider = ({ children }) => {
  const [numGuests, setNumGuests] = useState(0);
  const [submissions, setSubmissions] = useState([]);
  const cutoffDay = day("04/10/2022");
  const pastCutOff = day().isAfter(cutoffDay);
  const pricePerTicket = pastCutOff ? 300 : 250;
  const totalPrice =
    numGuests === 0 ? pricePerTicket : numGuests * pricePerTicket;
  const reset = () => {
    setNumGuests(0);
    setSubmissions([]);
  };
  const value = {
    numGuests,
    setNumGuests,
    submissions,
    setSubmissions,
    cutoffDay,
    pastCutOff,
    pricePerTicket,
    totalPrice,
    reset,
  };
  return (
    <GuestContext.Provider value={value}>{children}</GuestContext.Provider>
  );
};

export default GuestContext;
