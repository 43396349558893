import Stadium from "../../../../assets/images/stadium.jpg";
import { Grid, Image, useTheme } from "@chakra-ui/react";

export const StadiumBg = ({ children }) => {
  const { colors } = useTheme();
  return (
    <Grid minH="100vh" templateRows="auto 1fr">
      <Image
        src={Stadium}
        objectFit="cover"
        h="100vh"
        w="100vw"
        pos="fixed"
        border={`8px solid ${colors.gray["50"]}`}
        top={0}
        left={0}
      />
      {children}
    </Grid>
  );
};

export default StadiumBg;
