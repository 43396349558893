import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GuestContext } from "../../../context";

// if user submits a url guest num over the number of submissionsPresent then
// navigate to the closest guest submission or back to ticketing

export const useRedirectToTicketing = () => {
  const navigate = useNavigate();
  const { numGuests } = useContext(GuestContext);
  useEffect(() => {
    if (!numGuests) navigate("/ticketing");
  }, [numGuests, navigate]);
};
