import { Image, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import NJLogo from "../../../../assets/images/nj-logo.png";

export const Logo = props => (
  <Link
    as={RouterLink}
    to="/"
    boxSize={44}
    outline="none"
    _active={{ boxShadow: "none" }}
    _focus={{ boxShadow: "none" }}
    {...props}
  >
    <Image src={NJLogo} alt="nj-logo" color="gray.50" />
  </Link>
);

export default Logo;
