import { TextInput } from "../../../../../../form";
import { useField } from "formik";
import formatPhoneNumber from "../../../../../../../utils/formatPhoneNumber";

export const FirstName = ({ firstFieldRef }) => (
  <TextInput
    label="First Name"
    name="firstName"
    type="text"
    placeholder="John"
    autoComplete="given-name"
    isRequired
    inputRef={firstFieldRef}
  />
);

export const LastName = () => (
  <TextInput
    label="Last Name"
    name="lastName"
    type="text"
    placeholder="Smith"
    autoComplete="family-name"
    isRequired
  />
);

export const Email = () => (
  <TextInput
    label="Email"
    name="email"
    type="email"
    placeholder="johnsmith@gmail.com"
    autoComplete="email"
    isRequired
  />
);

export const Phone = () => {
  const [, , { setValue }] = useField("phone");
  return (
    <TextInput
      label="Mobile Phone # (ticket will be sent via sms)"
      name="phone"
      type="tel"
      placeholder="(555) 555-5555"
      onChange={({ target: { value } }) => setValue(formatPhoneNumber(value))}
      autoComplete="tel"
      isRequired
    />
  );
};
