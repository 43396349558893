import { useLocation } from "react-router-dom";
import { VStack, Text } from "@chakra-ui/react";

export const Disclaimers = () => {
  const location = useLocation();
  return (
    <VStack
      align="left"
      w="full"
      maxW={800}
      mt={4}
      mb={{ base: 2, md: 0 }}
      mx="auto"
      px={[4, 8, 12]}
    >
      {location.pathname.includes("guest") && (
        <Text variant="fine-print">
          *Completion of individual registration for each guest is required.
        </Text>
      )}
      {location.pathname.includes("registration") && (
        <Text variant="fine-print">
          *NJ FORWARD has partnered with VOW Digital Health to assist in the
          process of verifying guest vaccination status As well as the
          distribution of all digital tickets.
        </Text>
      )}
      {location.pathname.includes("payment") && (
        <Text variant="fine-print">
          Once payment is verified, you will receive a text message from VOW
          Digital Health to complete the ticketing and registration process.
        </Text>
      )}
    </VStack>
  );
};

export default Disclaimers;
