import { Formik, Form } from "formik";
import { VStack, Center } from "@chakra-ui/react";
import { FirstName, LastName, Email, Phone } from "./components";
import { SubmitButton } from "../../../../../../form";
import { useRegistrationFormUtils } from "./hooks";
import validationSchema from "./validationSchema";

export const RegistrationForm = ({ guestNum }) => {
  const formUtils = useRegistrationFormUtils(guestNum);
  const props = {
    enableReinitialize: true,
    initialValues: formUtils.initialValues,
    onSubmit: formUtils.onSubmit,
    validationSchema,
  };
  return (
    <>
      <VStack spacing={3}>
        <Formik {...props}>
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <VStack spacing={3} minW={300} maxW={415} m="0 auto">
                <FirstName firstFieldRef={formUtils.firstFieldRef} />
                <LastName />
                <Email />
                <Phone />
              </VStack>
              <Center mt={8}>
                <SubmitButton
                  isLoading={isSubmitting}
                  disabled={Object.values(values).includes("") || !isValid}
                  {...formUtils.submitButtonProps}
                />
              </Center>
            </Form>
          )}
        </Formik>
      </VStack>
    </>
  );
};

export default RegistrationForm;
