import { HStack, Center, Flex, useBreakpoint } from "@chakra-ui/react";
import { Hamburger, Link } from "./components";

export const Header = () => {
  const bp = useBreakpoint();
  const isMobile = bp === "base" || bp === "sm";
  return (
    <Center
      pos="sticky"
      top={[5, 5, 2]}
      bg={{ md: "#000000EE" }}
      mx={{ md: "8px" }}
      zIndex={3}
    >
      <Flex
        w="full"
        maxW="70em"
        align="center"
        justify="end"
        py={{ base: 4, md: 6 }}
        px={[4, 8, 12]}
      >
        {isMobile ? <Hamburger /> : <NavBar />}
      </Flex>
    </Center>
  );
};

const NavBar = () => (
  <HStack spacing={6}>
    <Link to="/terms-of-service">Terms of Service</Link>
    <Link to="/privacy-policy">Privacy Policy</Link>
    <Link to="/ticket-policy">Ticket Policy</Link>
    <Link to="/contact-us">Contact Us</Link>
    <Link to="/about">About</Link>
  </HStack>
);

export default Header;
