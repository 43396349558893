import axios from "axios";
import { authorizeNet } from "../env";
import { genUniqueId } from "./genUniqueId";

export const chargeCreditCard = async ({ cardData, amount, email }) => {
  const { baseUrl, apiLoginKey, transactionKey } = authorizeNet;

  const data = {
    createTransactionRequest: {
      merchantAuthentication: {
        name: apiLoginKey,
        transactionKey,
      },
      transactionRequest: {
        transactionType: "authCaptureTransaction",
        amount,
        payment: {
          creditCard: {
            cardNumber: cardData.cardNumber.replace(/\s/g, ""),
            expirationDate: `${cardData.expiryYear}-${cardData.expiryMonth}`,
            cardCode: cardData.csc,
          },
        },
        profile: {
          createProfile: true,
        },
        customer: {
          type: "individual",
          id: genUniqueId(20),
          email: email,
        },
      },
    },
  };

  try {
    const resp = await axios({
      method: "POST",
      url: baseUrl,
      headers: {
        "content-type": "application/json",
      },
      data,
    });
    console.log(resp);
    return handleResponse(resp);
  } catch (err) {
    console.log(err);
  }
};

const handleResponse = resp => {
  if (resp === null) return [false, "no response"];

  const { resultCode, message } = resp?.data?.messages;
  const txResp = resp.data.transactionResponse;
  const profResp = resp.data.profileResponse;

  const [txOk, txError] = checkTx(txResp);
  const [profOk, profError] = checkProf(profResp);

  if (resultCode === "Ok" && txOk && profOk) {
    return [txResp.transId, false];
  } else if (txError && profError) {
    return [false, `${txError} ${profError}`];
  } else if (txError) {
    return [false, txError];
  } else if (profError) {
    return [false, profError];
  } else if (message) {
    return [false, `${message[0].code} ${message[0].text}`];
  }
};

const checkTx = txResp => {
  if (!txResp) return [false, "No Transaction Response"];
  const { transId, responseCode, messages, errors } = txResp;
  if (messages) {
    console.log(
      `Successfully created transaction with Transaction ID: ${transId}`
    );
    console.log("Response Code: " + responseCode);
    console.log("Message Code: " + messages[0].code);
    console.log("Description: " + messages[0].description);
    return [true, null];
  } else if (errors) {
    const errorCode = errors[0].errorCode;
    const errorText = errors[0].errorText;
    console.log("Failed Transaction. ");
    console.log("Error Code: " + errorCode);
    console.log("Error message: " + errorText);
    return [false, errorText];
  }
};

const checkProf = profResp => {
  if (!profResp) return [false, "No Profile Response"];
  const { resultCode, message } = profResp.messages;
  if (resultCode === "Ok") {
    console.log(`Successfully created customer profile`);
    console.log("Result Code: " + resultCode);
    console.log("Message Code: " + message[0].code);
    console.log("Description: " + message[0].description);
    return [true, null];
  } else if (message) {
    const errorCode = message[0].code;
    const errorText = message[0].text;
    console.log("Failed to create customer profile. ");
    console.log("Error Code: " + errorCode);
    console.log("Error message: " + errorText);
    return [false, errorText];
  }
};
