import { useContext } from "react";
import { GuestContext } from "../../../../../context";
import { HStack, VStack, Text, chakra } from "@chakra-ui/react";
import { default as ReactCountdown } from "react-countdown";
import * as day from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

day.extend(advancedFormat);

const Countdown = chakra(ReactCountdown);

export const Prices = () => {
  const { cutoffDay, pastCutOff, pricePerTicket } = useContext(GuestContext);
  if (pastCutOff)
    return (
      <HStack w="full">
        <Text color="black">Price:</Text>
        <Text color="gray.600" fontSize={["lg", "xl"]}>
          ${pricePerTicket} each
        </Text>
      </HStack>
    );
  return (
    <VStack align="left" w="full" spacing={0}>
      <Text color="black">Price:</Text>
      <Text color="gray.600" fontSize={["lg", "xl"]}>
        $250 each BEFORE {cutoffDay.format("MMMM Do YYYY")}
      </Text>
      <Text color="gray.600" fontSize={["lg", "xl"]}>
        $300 each AFTER {cutoffDay.format("MMMM Do YYYY")}
      </Text>
      <HStack align="center">
        <Text color="gray.600" fontSize={["md", "lg"]}>
          Time Remaining:
        </Text>
        <Countdown
          date={cutoffDay.format()}
          fontSize={["md", "lg"]}
          fontFamily="mono"
        />
      </HStack>
    </VStack>
  );
};
