import { VStack, Text } from "@chakra-ui/react";
import { Title } from "../markup";

export const About = props => (
  <VStack spacing={[4, 6, 8]} align="left" {...props}>
    <Title>About</Title>
    <Text>
      New Jersey Forward Inc. is a registered, 501 (c) (4) not-for-profit
      social-welfare organization aiming to further the common good and general
      welfare of the people of New Jersey.
    </Text>
    <Text>
      The organization focuses on continuing the everlasting goal of moving New
      Jersey forward through engagement, discussions and purposeful community
      building.
    </Text>
  </VStack>
);
