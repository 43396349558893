import { Box, Image } from "@chakra-ui/react";
import Logo from "../../../../assets/images/nj-forward-logo.png";

export const NJForwardLogo = () => (
  <Box overflow="hidden" m={4}>
    <Image
      src={Logo}
      alt="NJ Forward"
      objectFit="contain"
      boxSize={["2xs", "3xs", "xs", "md", "md", "lg"]}
    />
  </Box>
);

export default NJForwardLogo;
