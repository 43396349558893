import { Section, Title, SubTitle } from "./markup";
import { VStack, Text } from "@chakra-ui/react";

export const InvitationText = props => (
  <VStack spacing={[4, 6, 8]} align="left" {...props}>
    <Section spacing={-1}>
      <SubTitle textTransform="uppercase">NJForward</SubTitle>
      <Text>Requests the honor of your presence</Text>
      <Text>To attend and participate</Text>
      <Text>In the celebration of</Text>
    </Section>

    <Section spacing={-1}>
      <Title>Philip D. Murphy</Title>
      <Text>56th Governor of the State of New Jersey</Text>
    </Section>

    <Text>And</Text>

    <Section spacing={-1}>
      <Title>Sheila Y. Oliver</Title>
      <Text>As the Lt. Governor of the State of New Jersey</Text>
    </Section>

    <Section spacing={-1}>
      <Text>Thursday, May 5th</Text>
      <Text>Two thousand and twenty-two</Text>
      <Text>6:30PM</Text>
      <Text>MetLife Stadium</Text>
      <Text>East Rutherford, New Jersey</Text>
    </Section>
    <Text>Attire: Black Tie</Text>
  </VStack>
);

export default InvitationText;
