import { useField } from "formik";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

export const Control = props => {
  const [field, meta] = useField(props);
  const styles = {
    variant: "filled",
    focusBorderColor: meta.value && !meta.error && "green.300",
  };
  return (
    <FormControl
      id={props.id || props.name}
      isRequired={props.isRequired}
      isInvalid={meta.touched && meta.error}
      w="full"
    >
      <FormLabel htmlFor={props.id || props.name} color="gray.600">
        {props.label}
      </FormLabel>
      {props.children({
        ...field,
        ...styles,
        onChange: props.onChange || field.onChange,
        textAlign: props.textAlign,
      })}
      <ErrorMessage {...meta} />
    </FormControl>
  );
};

const ErrorMessage = ({ error }) =>
  error ? <FormErrorMessage>{error}</FormErrorMessage> : null;

export default Control;
