import { useToast } from "@chakra-ui/react";

export const useRegistrationToast = () => {
  const toast = useToast();
  const displayToast = (status, message) => {
    if (status === "success")
      toast({
        status,
        title: "Registration Complete",
        position: "top",
        description: "Congratulations you've completed registration.",
        duration: 5000,
        isClosable: true,
      });
    if (status === "info")
      if (message === "add")
        toast({
          status,
          title: "Guest Added",
          position: "top",
          description:
            "You've added one guest to register. Please complete registration for all guests.",
          duration: 5000,
          isClosable: true,
        });
    if (message === "update")
      toast({
        status,
        title: "Guest Updated",
        position: "top",
        description:
          "You've successfully updated a guest to register. Please complete registration for all guests.",
        duration: 5000,
        isClosable: true,
      });

    if (status === "error")
      toast({
        status,
        title: "Error",
        position: "top",
        description: message,
        duration: 5000,
        isClosable: true,
      });
  };
  return displayToast;
};
