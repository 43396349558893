import { GuestContext } from "../../../../../../../context";
import { useNavigate } from "react-router-dom";
import { useRef, useContext } from "react";
import { useRegistrationToast } from "./useRegistrationToast";

export const useRegistrationFormUtils = guestNum => {
  const firstFieldRef = useRef();
  const navigate = useNavigate();
  const toast = useRegistrationToast();
  const { numGuests, submissions, setSubmissions } = useContext(GuestContext);

  const onSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);
      setSubmissions(prev => {
        const id = Number(guestNum) || 1;
        const alreadySubmitted = prev.find(guest => guest.id === id);
        if (alreadySubmitted) {
          if (guestNum) toast("info", "update");
          return prev.map(guest =>
            guest.id === id ? { ...values, id } : guest
          );
        }
        if (guestNum) toast("info", "add");
        return [...prev, { ...values, id }];
      });

      const onLastGuest = Number(guestNum) === Number(numGuests);
      if (!guestNum || onLastGuest) {
        // if only one guest or on last guest of registration
        toast("success");
        navigate("../../payment");
      } else {
        // move on to next guest
        actions.resetForm();
        firstFieldRef.current.focus();
        navigate(`../guest-${Number(guestNum) + 1}`);
      }
      actions.setSubmitting(false);
    } catch (err) {
      toast("error", err.message);
      actions.setSubmitting(false);
    }
  };

  const values = guestNum ? submissions[Number(guestNum) - 1] : submissions[0];
  const initialValues = values || {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  };

  const showNextGuest = guestNum && Number(guestNum) !== Number(numGuests);
  const submitButtonProps = {
    label: showNextGuest ? "Next Guest" : "Continue",
  };

  return { initialValues, onSubmit, firstFieldRef, submitButtonProps };
};
