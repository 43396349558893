import { Grid, Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { RegistrationForm } from "./components/RegistrationForm";
import { useRedirectToTicketing } from "../../hooks";

export const Registration = () => {
  const { guestNum } = useParams();
  useRedirectToTicketing();
  return (
    <Grid gap={4} justifyItems="center">
      {guestNum && <Heading fontSize="2xl">Guest {guestNum}</Heading>}
      <RegistrationForm guestNum={guestNum} />
    </Grid>
  );
};

export default Registration;
