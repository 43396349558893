import { theme as baseTheme } from "@chakra-ui/react";

export const Button = {
  sizes: {
    ...baseTheme.components.Button.sizes,
    xl: {
      maxW: 446,
      fontSize: "xl",
      py: 6,
      px: 8,
    },
  },
};

export default Button;
