import * as Yup from "yup";
import valid from "card-validator";

export const validationSchema = card =>
  Yup.object({
    nameOnCard: Yup.string().required("Name is required."),
    cardNumber: Yup.string()
      .test(
        "test-number",
        "Card # is invalid.",
        value => valid.number(value).isValid
      )
      .required("Card # is required"),
    expiryMonth: Yup.string().min(2).max(2).required("Month is required"),
    expiryYear: Yup.string().min(4).max(4).required("Year is required"),
    csc: Yup.string()
      .min(
        card?.code?.size || 3,
        `${card?.code?.name || "CSC"} must be at least ${
          card?.code?.size || 3
        } numbers.`
      )
      .max(
        card?.code?.size || 4,
        `${card?.code?.name || "CSC"} must be at least ${
          card?.code?.size || 4
        } numbers.`
      )
      .required(`${card?.code?.name || "CSC"} is required.`),
  });

export default validationSchema;
