import Control from "./Control";
import { Select } from "@chakra-ui/react";

export const SelectInput = ({ children, ...rest }) => (
  <Control {...rest}>
    {field => (
      <Select
        {...field}
        h={{ base: "70px", md: "60px" }}
        fontSize={rest.fontSize || ["lg", "md"]}
        variant="filled"
      >
        {children}
      </Select>
    )}
  </Control>
);

export default SelectInput;
