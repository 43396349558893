import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GuestContext } from "../../../../../context";

export const useUtils = () => {
  const { numGuests, setNumGuests } = useContext(GuestContext);
  const navigate = useNavigate();

  const onSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);
      setNumGuests(values.numGuests);
      if (values.numGuests > 1) navigate("registration/guest-1");
      else navigate("registration");
    } catch (err) {
      actions.setSubmitting(false);
    }
  };

  return { initialValues: { numGuests: numGuests || 1 }, onSubmit };
};

export default useUtils;

// right under mobile phone, Tickets will be issued via Text message.
