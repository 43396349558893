import { VStack, Text, ListItem } from "@chakra-ui/react";
import { Section, Title, SubTitle, List } from "../markup";

export const PrivacyPolicy = props => (
  <VStack spacing={[4, 6, 8]} align="left" {...props}>
    <Title>Privacy Policy</Title>
    <Section>
      <Text>Updated March 28, 2022</Text>
      <Text>
        The NJ Forward service and community network (collectively, “NJF”, or
        “the Service”) is operated by NJ Forward, and its corporate affiliates
        (collectively, “us”, “we”, “our” or “the Company”). This Privacy Policy
        is designed to help you understand how we collect and use the personal
        information you decide to share, and help you make informed decisions
        when using the NJF service located at njforward.org (“NJF” or “Web
        Site”). By using or accessing NJF, you are accepting the practices
        described in this Privacy Policy. This privacy statement covers the site
        njforward.org
      </Text>
    </Section>
    <Section>
      <SubTitle>Privacy of Donors</SubTitle>
      <Text>
        In a manner consistent with the following privacy policy, we respect and
        monitor the privacy of our online and offline donors. We make every
        effort to disclose donor information only as specifically instructed by
        the donor and in a manner consistent with his or her wishes, or as
        required by law. NJF may collect sensitive personal information, such as
        a donor’s credit card number, card type, expiration date, and keep a
        record of the financial transaction; however, NJF will not sell, trade,
        or share a donor’s personal information with anyone else, nor send donor
        mailings on behalf of other organizations. If you have a question about
        donor privacy, please contact us at info@njforward.org or 201 673 3566.
      </Text>
    </Section>
    <Section>
      <SubTitle>The Information We Collect</SubTitle>
      <Text>
        When you visit NJF you provide us with two types of information:
        personal information you knowingly choose to disclose that is collected
        by us and Web Site use information collected by us as you interact with
        our Web Site. When you register with NJF, you provide us with certain
        personal information, such as your name, your email address, your
        telephone number, your address, your gender and any other personal or
        preference information that you provide to us. When you enter the Web
        Site, we collect your browser type and IP address. This information is
        gathered from all NJF visitors. In addition, we store certain
        information from your browser using “cookies.” A cookie is a piece of
        data stored on the user’s computer tied to information about the user.
        We use session ID cookies to confirm that users are logged in. These
        cookies terminate once the user closes the browser. By default, we use a
        persistent cookie that stores your login ID (but not your password) to
        make it easier for you to login when you come back to NJF. You can
        remove or block this cookie using the settings in your browser if you
        want to disable this convenience feature. When you use the Web Site, you
        may form relationships, send messages, perform searches and queries,
        form groups, set up events, and transmit information through various
        channels. We collect this information so that we can provide you the
        service and offer personalized features. You post user content (as
        defined in the NJF Terms of Service) on the Web Site at your own risk.
        Although we allow you to set privacy options that limit access to your
        pages, please be aware that no security measures are perfect or
        impenetrable. We cannot control the actions of other Users with whom you
        may choose to share your pages and information. Therefore, we cannot and
        do not guarantee that User Content you post on the Site will not be
        viewed by unauthorized persons. We are not responsible for circumvention
        of any privacy settings or security measures contained on the Site. You
        understand and acknowledge that, even after removal, copies of User
        Content may remain viewable in cached and archived pages or if other
        Users have copied or stored your User Content. Any improper collection
        or misuse of information provided on NJF is a violation of the NJF Terms
        of Service and should be immediately reported by contacting us. By using
        NJF, you are consenting to have your personal data transferred to and
        processed in the United States.
      </Text>
    </Section>
    <Section>
      <SubTitle>Use of Information Obtained by NJF</SubTitle>
      <Text>
        When you register with NJF, you create your own profile and privacy
        settings. Your profile information, as well as your name, email and
        photo, are displayed to people in the networks specified in your privacy
        settings to enable you to connect with people on NJF . Profile
        information is used by NJF primarily to be presented back to and edited
        by you when you access the service and to be presented to others
        permitted to view that information by your privacy settings. Profile
        information you submit to NJF will be available to users of NJF who you
        allow to access the information through your privacy settings. We may
        use information about you that we collect from other sources, including
        but not limited to vaccination status, newspapers and Internet sources
        such as blogs, instant messaging services and other users of NJF, to
        supplement your profile. Where such information is used, we generally
        allow you to specify, in your privacy settings, that you do not want
        this to be done or to take other actions that limit the connection of
        this information to your profile (e.g., removing photo tag links).
      </Text>
    </Section>
    <Section>
      <SubTitle>Choice-Opt-Out</SubTitle>
      <Text>
        In order to provide service to you, we will occasionally send you
        communications related to your transactions, security or the
        administration of our website. We may also wish to send you our
        newsletter or other organizational communications. If you do not wish to
        receive communications from our team, please send an email to
        info@njforward.org. Please include your full name and email address in
        the email. You may also click on the unsubscribe link provided on each
        email and follow the instructions.
      </Text>
    </Section>
    <Section>
      <SubTitle>User Generated Content</SubTitle>
      <Text>
        By submitting communications or content to any part of njforward.org,
        including social media or other online platform, you agree that such
        submission is non-confidential and non-proprietary for all purposes. You
        further agree that NJF may use the posted content in any manner that it
        deems necessary or appropriate. NJF reserves the right to edit or
        abridge content for any reason. You understand that all information,
        data, text, photographs, graphics, messages, hashtags, or other
        materials publicly posted is the sole responsibility of the person from
        which such content originated. This means you, and not NJF, bears all
        risks and liabilities for all content that you upload, post, email,
        transmit or otherwise make available.
      </Text>
    </Section>
    <Section>
      <SubTitle>Sharing Your Information with Third Parties</SubTitle>
      <Text>
        NJF does not provide contact information to third party marketers
        without your permission. We share your information with third parties
        only in limited circumstances where such sharing is 1) reasonably
        necessary to offer a service, 2) legally required or, 3) permitted by
        you.
      </Text>
    </Section>
    <Section>
      <SubTitle>Links</SubTitle>
      <Text>
        NJF may contain links to other websites. We are not responsible for the
        privacy practices of other web sites. We encourage our users to be aware
        when they leave our site to read the privacy statements of each and
        every web site that collects personally identifiable information. This
        Privacy Policy applies solely to information collected by NJF.
      </Text>
    </Section>
    <Section>
      <SubTitle>Changing or Removing Information</SubTitle>
      <Text>
        Access and control over most personal information on NJF is readily
        available through the profile editing tools. Information will be updated
        immediately. NJF will make every effort to discontinue the use of an
        individual’s profile information as soon as practicable if requested by
        that individual. NJF may need to retain information in its archives and
        records to comply with law, resolve disputes, analyze problems, assist
        with any investigations, enforce NJF’s Terms of Service and other
        policies, and take other actions otherwise permitted or required by law.
      </Text>
    </Section>
    <Section>
      <SubTitle>Security</SubTitle>
      <Text>
        NJF takes appropriate precautions to protect our users’ information.
        Your account information is located on a secured server behind a Secure
        Sockets Layer (SSL) firewall. Because email and instant messaging are
        not recognized as secure communications, we request that you not send
        private information to us by email or instant messaging services. If you
        have any questions about the security of NJF Web Site, please call or
        email us.
      </Text>
    </Section>
    <Section>
      <SubTitle>Terms of Service, Notices and Revisions</SubTitle>
      <Text>
        If you choose to visit NJF, your visit and any dispute over privacy is
        subject to this Privacy Policy and our Terms of Service, including
        limitations on damages, arbitration of, and application of law of the
        state of New Jersey. We reserve the right to change our Privacy Policy
        and our Terms of Service at any time. We reserve the right, at our sole
        discretion, to change, modify, add, or delete portions of this Privacy
        Policy at any time without further notice. If we do this, we will post
        the changes to this Privacy Policy on this page and will indicate at the
        top of this page the date these terms were last revised, which shall
        constitute reasonable notice of such changes. Your continued use of the
        Service or the Sites after any such changes constitutes your acceptance
        of the new Privacy Policy. If you do not agree to abide by these or any
        future Privacy Policy, do not use or access (or continue to use or
        access) the Service or the Sites. It is your responsibility to regularly
        check the Sites to determine if there have been changes to these Privacy
        Policy and to review such changes.
      </Text>
    </Section>
    <Section>
      <SubTitle>
        Reviewing, Correcting and Removing Your Personal Information
      </SubTitle>
      <Text>You have the following date protection rights:</Text>
      <List>
        <ListItem>
          You can request access, correction, updated or deletion of your
          personal information.
        </ListItem>
        <ListItem>
          You can object to processing of your personal information, ask us to
          restrict processing of your personal information or request
          portability of your personal information.
        </ListItem>
        <ListItem>
          If we have collected and processed your personal information with your
          consent, then you can withdraw this consent at any time. Withdrawing
          your consent will not affect the lawfulness of any processing we
          conducted prior to your withdrawal, nor will it affect processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </ListItem>
      </List>
    </Section>
    <Section>
      <SubTitle>Contacting Us</SubTitle>
      <Text>
        If you have any questions about this privacy policy, please contact us.
      </Text>
      <Section spacing={-1}>
        <Text>You may also contact us by mail at:</Text>
        <Text>NJ Forward</Text>
        <Text>P.O. Box 755</Text>
        <Text>Oradell, NJ 07649</Text>
      </Section>
    </Section>
  </VStack>
);
