import { extendTheme } from "@chakra-ui/react";
import { Text, Button, Input, Select } from "./components";
import foundations from "./foundations";
import styles from "./styles";

const overrides = extendTheme({
  ...foundations,
  styles,
  components: {
    Text,
    UnorderedList: { ...Text },
    OrderedList: { ...Text },
    List: { ...Text },
    Button,
    Input,
    Select,
  },
});

const theme = extendTheme(overrides);

export default theme;
