import { Formik, Form } from "formik";
import { VStack, Center, Heading } from "@chakra-ui/react";
import { NumTicketsInput, Prices } from "./components";
import SubmitButton from "../../../../form/SubmitButton";
import TotalPrice from "../TotalPrice";
import validationSchema from "./validationSchema";
import { useUtils } from "./hooks";

export const SelectNumTickets = props => {
  const { initialValues, onSubmit } = useUtils(props);
  const formikProps = { initialValues, validationSchema, onSubmit };
  return (
    <VStack spacing={4}>
      <Heading>Tickets</Heading>
      <Prices />
      <Formik {...formikProps}>
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <VStack spacing={3} minW={300} maxW={415} m="0 auto">
              <NumTicketsInput />
            </VStack>
            <Center mt={4}>
              <SubmitButton
                isLoading={isSubmitting}
                disabled={Object.values(values).includes("") || !isValid}
              />
            </Center>
          </Form>
        )}
      </Formik>
      <TotalPrice />
    </VStack>
  );
};

export default SelectNumTickets;
