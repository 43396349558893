import { VStack, Text } from "@chakra-ui/react";

export const Footer = ({ children, color, ...rest }) => (
  <VStack
    w="full"
    maxW="70em"
    spacing={8}
    px={[4, 8, 12]}
    mx="auto"
    my="12px"
    {...rest}
  >
    {children}
    <Text variant="fine-print" textAlign="center" color={color}>
      Paid for by New Jersey Forward Inc., a registered 501(c) (4)
      social-welfare organization. Donations to this organization are not
      deductible for federal income tax purposes as charitable contributions.
    </Text>
  </VStack>
);

export default Footer;
