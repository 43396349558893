import { VStack, Text } from "@chakra-ui/react";
import { Title } from "../markup";

export const TicketPolicy = props => {
  return (
    <VStack spacing={[4, 6, 8]} align="left" {...props}>
      <Title>Ticket Policy</Title>
      <Text>
        All purchases are nontransferable and nonrefundable. By purchasing a
        ticket, you agree to provide your name, email address and cell phone
        number for the purposes of verifying your identity as well as your
        vaccination status. Tickets will be issued individually using SMS/Text
        service.
      </Text>
    </VStack>
  );
};
