import axios from "axios";
import { jotform } from "../env";

const baseUrl = "https://api.jotform.com";
const { apiKey } = jotform;
const registrationFormName = "Inaugural Ball - DO NOT DELETE";
const registrationFormNameCamel = "inauguralBall";

const sendRequest = async ({ method, endpoint = "", params, data }) => {
  try {
    const resp = await axios({
      method,
      url: baseUrl + endpoint,
      params: {
        apiKey,
        ...params,
      },
      data,
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const getForm = async id => {
  await sendRequest({ method: "GET", endpoint: `/${id}` });
};

const getForms = async () => {
  const { content: forms } = await sendRequest({
    method: "GET",
    endpoint: "/user/forms",
  });
  return forms;
};

const getRegistrationForm = async () => {
  const forms = await getForms();
  return forms.find(
    form => form.title === registrationFormName && form.status === "ENABLED"
  );
};

const findOrCreateRegistrationForm = async () => {
  const form = await getRegistrationForm();
  if (form) return form;
  return await createRegistrationForm();
};

export const getFormQuestions = async form => {
  const data = await sendRequest({
    method: "GET",
    endpoint: `/form/${form.id}/questions`,
  });
  return data.content;
};

export const addSubmission = async data => {
  const form = await findOrCreateRegistrationForm();
  const questions = await getFormQuestions(form);
  const params = Object.values(questions).reduce((o, question) => {
    if (data[question.name])
      o[`submission[${question.qid}]`] = data[question.name];
    return o;
  }, {});

  try {
    await sendRequest({
      method: "POST",
      endpoint: `/form/${form.id}/submissions`,
      params,
    });
    return ["success"];
  } catch (err) {
    return [false, err];
  }
};

export const createForm = async params => {
  const { content } = await sendRequest({
    method: "POST",
    params,
    endpoint: "/user/forms",
  });
  return content;
};

const createRegistrationForm = async () => {
  const data = {
    properties: { title: registrationFormName },
    questions: [
      {
        type: "control_head",
        text: registrationFormName,
        name: registrationFormNameCamel,
        order: "1",
      },
      {
        type: "control_textbox",
        name: "firstName",
        text: "First Name",
        order: "2",
      },
      {
        type: "control_textbox",
        name: "lastName",
        text: "Last Name",
        order: "3",
      },
      {
        type: "control_email",
        name: "email",
        text: "Email",
        order: "4",
      },
      {
        type: "control_textbox",
        name: "phone",
        text: "Phone",
        order: "5",
      },
    ],
  };
  return await createForm(convertDataToParams(data));
};

const convertDataToParams = data => {
  return Object.entries(data).reduce((o, [key, props]) => {
    if (Array.isArray(props)) {
      props.forEach((prop, i) => {
        Object.entries(prop).forEach(([propKey, propValue]) => {
          o[`[${key}][${i + 1}][${propKey}]`] = propValue;
        });
      });
    } else {
      Object.entries(props).forEach(([propKey, propValue]) => {
        o[`[${key}][${propKey}]`] = propValue;
      });
    }
    return o;
  }, {});
};
