const allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];
const allLowerAlpha = [..."abcdefghijklmnopqrstuvwxyz"];
const allNumbers = [..."0123456789"];

const base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

export const genUniqueId = len => {
  return [...Array(len)]
    .map(i => base[(Math.random() * base.length) | 0])
    .join("");
};
