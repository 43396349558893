import valid from "card-validator";
import { GuestContext } from "../../../../../context";
import { chargeCreditCard, addSubmission } from "../../../../../../services";
import { formatCardNumber } from "../../../../../../utils/formatCardNumber";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { usePaymentToast } from "./usePaymentToast";

export const usePaymentFormUtils = setCard => {
  const navigate = useNavigate();
  const toast = usePaymentToast();
  const { submissions, totalPrice } = useContext(GuestContext);

  const initialValues = {
    nameOnCard: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    csc: "",
  };

  const onCardNumberChange = value => {
    const { card } = valid.number(value);
    setCard(card);
    return formatCardNumber(value, card);
  };

  const uploadRegistration = async txId => {
    try {
      await Promise.all(
        submissions.map(guest => addSubmission({ ...guest, txid: txId }))
      );
    } catch (err) {
      throw err;
    }
  };

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const [txId, error] = await chargeCreditCard({
        cardData: values,
        amount: totalPrice,
        email: submissions[0].email,
      });
      if (error) throw new Error(error);
      if (txId) {
        // send registration information
        await uploadRegistration(txId);
        toast("success");
        navigate("thank-you");
      }
    } catch (err) {
      toast("error", err);
    }
    actions.setSubmitting(false);
  };

  return { initialValues, onSubmit, onCardNumberChange };
};
