import Link from "./Link";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  VStack,
  HStack,
  Grid,
  Collapse,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";

export const Hamburger = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const location = useLocation();
  useEffect(() => {
    onClose();
  }, [location, onClose]);
  return (
    <Grid w="full">
      <HStack justifySelf="end" spacing={6}>
        <IconButton
          aria-label="pages"
          icon={
            isOpen ? <IoMdClose size={35} /> : <GiHamburgerMenu size={35} />
          }
          variant="unstyled"
          _active={{ boxShadow: "none" }}
          _focus={{ boxShadow: "none" }}
          color="white"
          size="lg"
          onClick={onToggle}
        />
      </HStack>
      <Collapse in={isOpen}>
        <VStack
          align="stretch"
          bg="#000000EE"
          borderRadius="md"
          my={4}
          spacing={6}
          py={6}
        >
          <Link to="about">About</Link>
          <Link to="contact-us">Contact Us</Link>
          <Link to="ticket-policy">Ticket Policy</Link>
          <Link to="privacy-policy">Privacy Policy</Link>
          <Link to="terms-of-service">Terms of Service</Link>
        </VStack>
      </Collapse>
    </Grid>
  );
};
