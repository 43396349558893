import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Center, Heading } from "@chakra-ui/react";
import { GuestContext } from "../../../context";

export const ThankYou = () => {
  const navigate = useNavigate();
  const { numGuests, reset } = useContext(GuestContext);
  useEffect(() => {
    const delay = setTimeout(() => {
      reset();
    }, 10000);
    return delay;
  });

  // if user submits a url guest num over the number of submissionsPresent then
  // navigate to the closest guest submission or back to ticketing
  useEffect(() => {
    if (!numGuests) navigate("../../../ticketing");
  }, [numGuests, navigate]);

  return (
    <Center h="50%">
      <Heading>Thank you!</Heading>
    </Center>
  );
};

export default ThankYou;
