import { useField } from "formik";
import { TextInput, SelectInput, CardIcon } from "../../../../form";

export const NameOnCard = () => (
  <TextInput
    label="Name"
    name="nameOnCard"
    type="text"
    placeholder="John Smith"
    autoComplete="cc-name"
    isRequired
  />
);

export const CardNumber = ({ card, onChange }) => {
  const [, , { setValue }] = useField("cardNumber");
  return (
    <TextInput
      label="Card #"
      name="cardNumber"
      placeholder="5555 5555 5555 5555"
      autoComplete="cc-number"
      onChange={({ target: { value } }) => setValue(onChange(value))}
      inputLeftElement={
        card && {
          pointerEvents: "none",
          children: <CardIcon {...card} />,
        }
      }
      isRequired
    />
  );
};

export const ExpiryMonth = () => {
  const months = (() => {
    return Array.from({ length: 12 }).map((_, index) => {
      if (index + 1 < 10) return `0${index + 1}`;
      return `${index + 1}`;
    });
  })();
  return (
    <SelectInput
      label="Month"
      name="expiryMonth"
      type="text"
      maxLength={2}
      placeholder="-"
      autoComplete="cc-exp-month"
      isRequired
    >
      <option value="">-</option>
      {months.map(m => (
        <option value={m} key={m}>
          {m}
        </option>
      ))}
    </SelectInput>
  );
};

export const ExpiryYear = () => {
  const years = (() => {
    const current = new Date().getFullYear();
    return Array.from({ length: 19 }).map((_, index) => `${current + index}`);
  })();
  return (
    <SelectInput
      label="Year"
      name="expiryYear"
      maxLength={4}
      type="text"
      isRequired
      autoComplete="cc-exp-year"
      placeholder="-"
    >
      <option value="">-</option>
      {years.map(y => (
        <option value={y} key={y}>
          {y}
        </option>
      ))}
    </SelectInput>
  );
};

export const SecurityCode = ({ card }) => (
  <TextInput
    label={card?.code?.name || "CSC"}
    name="csc"
    type="number"
    placeholder={`${
      card ? Array.from({ length: card.code.size }).fill(5).join("") : "555"
    }`}
    autoComplete="cc-csc"
    isRequired
  />
);
