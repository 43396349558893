export const Text = {
  baseStyle: {
    color: "gray.50",
    fontSize: ["lg", "xl", "2xl", "3xl"],
    fontWeight: "bold",
  },
  variants: {
    "fine-print": {
      align: "justify",
      color: "gray.500",
      fontSize: ["xs", "sm", "md", "md", "lg"],
    },
  },
};

export default Text;
