export const formatCardNumber = (value, card) => {
  // clean the input for any non-digit values.
  let number = value.replace(/[^\d]/g, "");

  if (!card) return number;

  for (let i = 0; i < card.gaps.length; i++) {
    const gap = card.gaps[i];

    if (number.length > gap) {
      if (number[gap] === " ") continue;

      number = number.slice(0, gap) + " " + number.slice(gap);

      card.gaps[i + 1] += i + 1;
    }
  }

  const maxLength = Math.max(...card.lengths) + card.gaps.length;

  if (number.length >= maxLength) number = number.slice(0, maxLength - 1);

  return number;
};
