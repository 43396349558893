import { theme as baseTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  ...baseTheme.breakpoints,
  xl: "70em",
});

export const foundations = {
  breakpoints,
  fonts: {
    heading: "Neutra Text, sans-serif",
    body: "Neutra Text, sans-serif",
  },
  colors: {
    brand: {
      "light-blue": "#4698CB",
      blue: "#0076A8",
      yellow: "#FFC56E",
      navy: "#01426A",
    },
  },
};

export default foundations;
