import { Button, Link as ChakraLink } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export const Link = ({ to, children, ...rest }) => (
  <ChakraLink as={RouterLink} to={to}>
    <Button
      variant="link"
      color="white"
      fontSize={{ base: "md", lg: "lg" }}
      textTransform="uppercase"
      outline="none"
      _active={{ boxShadow: "none" }}
      _focus={{ boxShadow: "none" }}
      w="full"
      {...rest}
    >
      {children}
    </Button>
  </ChakraLink>
);

export default Link;
