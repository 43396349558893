import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./components";
import { render } from "react-dom";
import theme from "./theme";
import "./fonts.css";
import { GuestProvider } from "./components/context/GuestContext";
// add jotform env

const Root = () => (
  <ChakraProvider theme={theme}>
    <GuestProvider>
      <Router>
        <App />
      </Router>
    </GuestProvider>
  </ChakraProvider>
);

render(<Root />, document.getElementById("root"));
