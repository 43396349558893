import { Button } from "@chakra-ui/react";

export const SubmitButton = ({ label, disabled, ...rest }) => (
  <Button
    type="submit"
    loadingText="Submitting"
    colorScheme={disabled ? "gray" : "blue"}
    h={{ base: "70px", md: "60px" }}
    fontSize={{ base: "xl", md: "lg" }}
    w="full"
    minW={300}
    maxW={415}
    textTransform="uppercase"
    {...rest}
  >
    {label || "Submit"}
  </Button>
);

export default SubmitButton;
