import { VStack, Heading, UnorderedList, OrderedList } from "@chakra-ui/react";

export const Section = ({ children, ...rest }) => (
  <VStack align="left" spacing={1} {...rest}>
    {children}
  </VStack>
);

export const Title = ({ children, ...rest }) => (
  <Heading
    as="h1"
    color="gray.50"
    fontSize={["3xl", "4xl", "5xl", "6xl", "7xl"]}
    {...rest}
  >
    {children}
  </Heading>
);

export const SubTitle = ({ children, ...rest }) => (
  <Heading
    as="h2"
    color="gray.50"
    fontSize={["xl", "2xl", "3xl", "4xl", "5xl"]}
    {...rest}
  >
    {children}
  </Heading>
);
export const List = ({ type, children, ...rest }) =>
  type === "ordered" ? (
    <OrderedList
      color="gray.50"
      fontSize={["lg", "xl", "2xl", "3xl"]}
      fontWeight="bold"
      p={4}
      spacing={4}
      {...rest}
    >
      {children}
    </OrderedList>
  ) : (
    <UnorderedList
      color="gray.50"
      fontSize={["lg", "xl", "2xl", "3xl"]}
      fontWeight="bold"
      p={4}
      spacing={4}
      {...rest}
    >
      {children}
    </UnorderedList>
  );
